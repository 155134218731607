import {
  PostInitiateTextchatResponse,
  PostMessagesResponse,
  PostSendMessageResponse,
  PostStartResponse,
  SendMessageRequest,
  //   TypingStateRequest,
} from '@cds/chatbot-client';
import { createServer, Model, Response } from 'miragejs';

import { BuildConfig } from './config';

export type MakeServerArgs = {
  environment: string;
  config: BuildConfig;
};

const replies = [
  'Mit der FinanzAnalyse werden die Umsätze Ihrer Girokonten automatisch in Kategorien eingeteilt und einer Kategorie zugeordnet.',
  'Ich habe Sie noch nicht genau verstanden.',
  'Bei einem Gemeinschaftskonto handelt es sich um ein Zahlungsverkehrskonto.',
  'Für eine Überweisung außerhalb des SEPA-Raums oder eine Überweisung in einer Fremdwährung ist die Durchführung einer Auslandsüberweisung erforderlich.',
  'Mit einem Freistellungsauftrag können Sie einen steuerlichen Freibetrag für Ihre Kapitalerträge geltend machen.',
  'Um einen Freistellungsauftrag zu erteilen, müssen Sie Ihre Steueridentifikationsnummer angeben.',
  'Ihre Sammellastschriften werden in Ihrer Auftragsübersicht aufgelistet.',
  'Sollten Sie als Unternehmerkunde eine Sammellastschrift löschen wollen, können Sie dies bequem über Ihr Online Banking tun.',
  'Ihren aktuellen Dispositionskredit können Sie bequem in Ihrem Online Banking einsehen.',
  'Wählen Sie für die Terminvereinbarung zuerst das Thema aus.',
];

export function makeServer({ environment = 'test', config }: MakeServerArgs) {
  let server = createServer({
    environment,

    models: {
      message: Model,
    },

    seeds(server) {
      const timestamp = new Date().toISOString();
      server.db.loadData({
        messages: [
          {
            displayName: 'Customer',
            participantId: 'aabdf54d-e863-4ec9-a7e8-fd63e383897b',
            participantType: 'WebUser',
            sequenceNumber: 0,
            state: 2,
            // timestamp: '2024-09-13T08:23:56.047103Z',
            timestamp,
            __type: 'urn:inin.com:webChat:chatParticipantStateChangedMessage',
          },
          {
            contentType: 'text/plain',
            participantId: '00000000-0000-0000-0000-000000000000',
            sequenceNumber: 0,
            // timestamp: '2024-09-13T08:23:56.07834967',
            timestamp,
            value: 'Herzlich Willkommen bei der Commerzbank.',
            __type: 'urn:inin.com:webChat:chatTextMessage',
          },
          {
            contentType: 'text/plain',
            participantId: '00000000-0000-0000-0000-000000000000',
            sequenceNumber: 0,
            timestamp,
            value: 'Ihr Kontakt wurde aufgebaut',
            __type: 'urn:inin.com:webChat:chatTextMessage',
          },
          {
            displayName: 'Bot#Commerzbank ChatBot',
            participantId: '7380260a-c635-4b67-ac76-8df233417a5a',
            participantType: 'Agent',
            sequenceNumber: 2,
            state: 2,
            timestamp,
            __type: 'urn:inin.com:webChat:chatParticipantStateChangedMessage',
          },
          {
            displayName: 'Bot#Commerzbank ChatBot',
            participantId: '7380260a-c635-4b67-ac76-8df233417a5a',
            participantType: 'Agent',
            sequenceNumber: 3,
            state: 2,
            timestamp,
            __type: 'urn:inin.com:webChat:chatParticipantStateChangedMessage',
          },
          {
            contentType: 'text/plain',
            participantId: '7380260a-c635-4b67-ac76-8df233417a5a',
            sequenceNumber: 0,
            timestamp,
            value: 'Hallo!',
            __type: 'urn:inin.com:webChat:chatTextMessage',
          },
          {
            contentType: 'text/plain',
            participantId: '7380260a-c635-4b67-ac76-8df233417a5a',
            sequenceNumber: 0,
            timestamp,
            value:
              'Du kannst mir Nachrichten schreiben, die ich dir dann direkt zurücksende.',
            __type: 'urn:inin.com:webChat:chatTextMessage',
          },
        ],
      });
    },

    routes() {
      this.post('/chat/web/v1/initiate', () => {
        const responseBody: PostInitiateTextchatResponse = {
          result: {
            data: {
              mobileAppAvailability: {
                workgroupName: 'MOCK_WORKGROUP',
              },
            },
          },
        };
        return new Response(200, {}, responseBody);
      });

      this.post('/chat/web/v1/start', () => {
        const responseBody: PostStartResponse = {
          result: {
            data: {
              chatId: '0123456789',
              participantId: '42',
              pollWaitSuggestion: 2000,
            },
          },
        };
        return new Response(200, {}, responseBody);
      });

      this.post('/chat/web/v1/typing-state', () => {
        // const json = JSON.parse(request.requestBody) as TypingStateRequest;
        return new Response(200, {}, {});
      });

      this.post('/chat/web/v1/send-message', (schema, request) => {
        const json = JSON.parse(request.requestBody) as SendMessageRequest;
        const timestamp = new Date().toISOString();

        const processedMsg = {
          contentType: 'text/plain',
          participantId: '42',
          sequenceNumber: 0,
          timestamp,
          value: json.message,
          __type: 'urn:inin.com:webChat:chatTextMessage',
        };

        const reply = {
          contentType: 'text/plain',
          participantId: '7380260a-c635-4b67-ac76-8df233417a5a',
          sequenceNumber: 1,
          timestamp,
          value: `${replies[Math.floor(Math.random() * replies.length)]}`,
          __type: 'urn:inin.com:webChat:chatTextMessage',
        };

        schema.db.messages.insert(processedMsg);
        schema.db.messages.insert(reply);

        const responseBody: PostSendMessageResponse = {};

        return new Response(200, {}, responseBody);
      });

      this.post('/chat/web/v1/messages', (schema) => {
        const messages = schema.db.messages.where({});
        schema.db.messages.remove();

        const responseBody: PostMessagesResponse = {
          result: {
            data: {
              messages,
              pollWaitSuggestion: 2000,
            },
          },
        };

        return new Response(200, {}, responseBody);
      });

      this.passthrough();
      this.passthrough(`${config.server.chat.apiBaseUrl}/**`);
      this.passthrough(`${config.server.search.apiUrl}/**`);
      this.passthrough(`${config.server.tracking.snowplow.apiBaseUrl}/**`);
      this.passthrough('https://vimeo.com/**');
    },
  });

  return server;
}
