import { createContext, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import mergeWith from 'lodash/mergeWith';
import { MostReadArticleType, NewsDataType } from 'pages';

type CategoryInfo = {
  icon: string;
  tag: string;
  label: string;
  altText?: string;
  description?: string;
};

export type BuildStage = 'local' | 'dev' | 'test' | 'prod';

export type AppSettings = {
  build: {
    /**
     * The deployment stage of the application. (dev, test, prod)
     *
     * TODO(benjamin): This should ideally not be exposed to the client, but currently there are dependencies on this value.
     */
    stage: BuildStage;
  };
  site: {
    /**
     * The hostname of the application.
     */
    hostname: string;

    /**
     * The basename of the application.
     */
    basename: string;

    /**
     * The language of the application.
     */
    language: 'de' | 'en';
  };
  metadata: {
    /**
     * The meta description for the index page.
     */
    indexDescription: string;
  };

  /**
   * TODO(benjamin): Ideally, this should be hidden from the client.
   */
  fetch?: {
    /**
     * Whether or not to fetch the news data using DataFetcher during the build process .
     */
    newsData: boolean;

    /**
     * Whether or not to fetch the top articles using DataFetcher during the build process.
     */
    mostReadArticles: boolean;

    /**
     * Whether or not to fetch the top search terms using DataFetcher during the build process.
     */
    topSearchTerms: boolean;
  };

  staticContent: {
    links: {
      logo: string;
      terms: string;
      security: string;
      imprint: string;
      legal: string;
      contact: string;
    };
    categories: [string, CategoryInfo][];
    switchPortal: {
      url: string;
      headlineKey: string;
    };
    newsData: NewsDataType[];
    mostReadArticles: MostReadArticleType[];
    showBreadcrumbs: boolean;
    showMostReadArticles: boolean;
    showTopicPages: boolean;
    alternativeLanguageDomains?: {
      language: AppSettings['site']['language'];
      portalUrl: string;
    }[];
    homeBreadrumbKey: string;
    googleSiteVerificationTag?: string;
  };
  search: {
    apiUrl: string;
    portalUrl?: string;
    topSearchTerms: string[];
    badgeRules: 'puk' | 'nachKon';
  };
  tracking: {
    requireConsent: boolean;

    snowplow: {
      appId: string;
      apiBaseUrl: string;
    };
    ga4: {
      enabled: boolean;
    };
  };
  chat: {
    enable: boolean;
    enableMock: boolean;
    apiBaseUrl: string;
  };
  portal: {
    /**
     * URLs that are considered internal portal links
     */
    knownPortals: string[];

    /**
     * URLs that are considered adaptive to staging environments.
     */
    stageDependentUrls?: string[];

    /**
     * Domains that will trigger link adaptation when the browser location matches.
     */
    stageOrigins?: string[];
  };
};

const useMergedSettings = (config: BuildConfig): AppSettings => {
  const { query } = useRouter();

  const [mergedSettings, setMergedSettings] = useState<Partial<BuildConfig>>(
    {},
  );

  useEffect(() => {
    const mergedConfig: Partial<BuildConfig> = config.runtime.reduce(
      (prev, { paramName, matchType, options }) => {
        const paramValue = query[paramName];
        if (Array.isArray(paramValue)) {
          return prev;
        }

        if (paramValue !== undefined) {
          options.forEach((option) => {
            const matchValues = option.matchValues;
            const optionConfig = option.config;

            if (
              (matchType === 'literal' && matchValues.includes(paramValue)) ||
              (matchType === 'regex' &&
                matchValues.some((value) => new RegExp(value).test(paramValue)))
            ) {
              prev = mergeWith(prev, optionConfig, (objValue, srcValue) => {
                if (Array.isArray(objValue)) {
                  return srcValue;
                }
              });
            }
          });
        }

        return prev;
      },
      {},
    );

    setMergedSettings(mergedConfig);
  }, [config.runtime, query]);

  return mergeWith(config.server, mergedSettings, (objValue, srcValue) => {
    if (Array.isArray(objValue)) {
      return srcValue;
    }
  });
};

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object | undefined
      ? RecursivePartial<T[P]>
      : T[P];
};

export type BuildConfig = {
  server: AppSettings;
  runtime: {
    paramName: string;
    matchType: 'regex' | 'literal';
    options: {
      matchValues: string[];
      config: RecursivePartial<Omit<AppSettings, 'build' | 'fetch'>>;
    }[];
  }[];
};

export type RuntimeSettingsContextData = {
  settings: AppSettings;
};
export const RuntimeSettingsContext =
  createContext<RuntimeSettingsContextData | null>(null);
export const RuntimeSettingsContextProvider: React.FC<
  React.PropsWithChildren<{
    config: BuildConfig;
  }>
> = ({ config, children }) => {
  const runtimeSettings = useMergedSettings(config);

  return (
    <RuntimeSettingsContext.Provider value={{ settings: runtimeSettings }}>
      {children}
    </RuntimeSettingsContext.Provider>
  );
};

export function useRuntimeSettings(): AppSettings {
  const ctx = useContext(RuntimeSettingsContext);

  if (ctx === null) {
    throw new Error(
      'No AppSettings found in context. Make sure useRuntimeSettings is used within a ConfigContextProvider and provides the correct data.',
    );
  }

  return ctx.settings;
}
