import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { GA4TrackScrollPercentage } from './tracking';

const loggedPercentages = new Set<number>();

const handleScroll = () => {
  const scrollTop = window.scrollY || document.documentElement.scrollTop;
  const scrollHeight =
    document.documentElement.scrollHeight - window.innerHeight;
  const scrolled = Math.ceil((scrollTop * 100) / scrollHeight);

  const thresholds = [25, 50, 75, 100];
  const lowerRange = 5;
  const upperRange = 15;

  thresholds.forEach((value, index) => {
    if (
      !loggedPercentages.has(value) &&
      scrolled >= value - lowerRange &&
      scrolled < value + upperRange
    ) {
      GA4TrackScrollPercentage(value);
      // Mark current and all previous thresholds as fired
      thresholds
        .slice(0, index + 1)
        .forEach((value) => loggedPercentages.add(value));
    }
  });
};

const resetScrollTracking = () => {
  loggedPercentages.clear();
};

const useScrollTracker = () => {
  const router = useRouter();

  useEffect(() => {
    window.addEventListener('scroll', () => handleScroll());
    return () => {
      window.removeEventListener('scroll', () => handleScroll());
    };
  }, []);

  useEffect(() => {
    // Reset scroll tracking when the route (page) changes
    router.events.on('routeChangeComplete', resetScrollTracking);
    return () => {
      router.events.off('routeChangeComplete', resetScrollTracking);
    };
  }, [router.events]);
};

export default useScrollTracker;
