export enum DeviceTypes {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  DESKTOP = 'DESKTOP',
  OTHER = 'OTHER',
}

export class MobileChecker {
  deviceType: DeviceTypes;

  constructor() {
    this.deviceType = this.getDeviceType();
  }

  private getDeviceType = (): DeviceTypes => {
    if (typeof window !== 'undefined' && navigator) {
      const ua = navigator?.userAgent || '';
      if (/(iP(hone|od|ad))/i.test(ua)) {
        return DeviceTypes.IOS;
      }
      if (/Android|BlackBerry/i.test(ua)) {
        return DeviceTypes.ANDROID;
      }
      return DeviceTypes.DESKTOP;
    }
    return DeviceTypes.DESKTOP;
  };

  public isMobile = () => {
    return (
      this.deviceType === DeviceTypes.IOS ||
      this.deviceType === DeviceTypes.ANDROID
    );
  };
}
